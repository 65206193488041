export const ALL = '*'
export const AUTH = 'auth'
export const ACTIVITIES = 'activities'
export const BILLING = 'billing'
export const BILLING_ID = ':billingId'
export const COMPANIES = 'companies'
export const COMPANY_ID = ':companyId'
export const COMPLIANCE_ALERTS = 'compliance-alerts'
export const COMPLIANCE_ALERT_ID = ':complianceAlertId'
export const TOOLS = 'tools'
export const PAYRUNS = 'payruns'
export const PAYOUTS = 'payouts'
export const INVOICES = 'invoices'
export const INVOICE_ID = ':invoiceId'
export const PAYRUN_ID = ':payrunId'
export const SETTINGS = 'settings'
export const AGENTS = 'agents'
export const DETAILS = 'details'
export const KYB = 'kyb'
export const TOPUPS = 'topups'
export const TOPUP_ID = ':topupId'
export const AUDIT_LOG = 'audit-log'
