import { sharedGlobalStyles } from 'salad/shared-global-styles'
import { globalCss } from 'salad/stitches'

export const globalStyles = globalCss(...sharedGlobalStyles, {
  '#root, #storybook-root': {
    height: '100%',
    '&.blurred': {
      filter: 'blur(4px)',
    },
  },
})
