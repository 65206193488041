import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CacheKey } from 'kitchen/constants'
import type {
  FetchError,
  MutationHookFactory,
  UseMutationOptions,
  UseQueryOptions,
} from 'kitchen/types'
import { publicApi } from 'kitchen/utils/api'
import { immutableRequestOptions } from 'kitchen/utils/fetch'
import {
  getAllAgents,
  getCurrentAgent,
  toggleAgentRole,
  toggleAgentState,
} from '../requests/bo-agents'
import type {
  AgentResponse,
  AgentToggleRolePayload,
  AgentToggleStatePayload,
} from '../types/bo-agents'

export function useCurrentAgent(options?: UseQueryOptions<AgentResponse>) {
  return useQuery<AgentResponse, FetchError>(
    [CacheKey.CURRENT_AGENT],
    ({ signal }) => getCurrentAgent(publicApi, signal),
    {
      ...immutableRequestOptions,
      ...options,
    }
  )
}

export function useAgents(options?: UseQueryOptions<AgentResponse[]>) {
  return useQuery<AgentResponse[], FetchError>(
    [CacheKey.ALL_AGENTS],
    ({ signal }) => getAllAgents(publicApi, signal),
    {
      ...immutableRequestOptions,
      ...options,
    }
  )
}

export interface AgentToggleRoleOptions
  extends UseMutationOptions<AgentToggleRolePayload, void> {}

export function useToggleRole(options?: AgentToggleRoleOptions) {
  const queryClient = useQueryClient()

  return useMutation((payload) => toggleAgentRole(publicApi, payload), {
    ...options,
    async onSuccess(data, payload, context) {
      await queryClient.invalidateQueries([CacheKey.ALL_AGENTS])
      return options?.onSuccess?.(data, payload, context)
    },
  })
}

export const useToggleState: MutationHookFactory<AgentToggleStatePayload, void> = (
  options
) => {
  const queryClient = useQueryClient()

  return useMutation((payload) => toggleAgentState(publicApi, payload), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([CacheKey.ALL_AGENTS])

      return options?.onSuccess?.(data, variables, context)
    },
  })
}
