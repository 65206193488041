import type { ApiRequest } from 'kitchen/types'
import type {
  AgentResponse,
  AgentToggleRolePayload,
  AgentToggleStatePayload,
} from '../types/bo-agents'

export const getCurrentAgent: ApiRequest<void, AgentResponse> = (fetch, signal) =>
  fetch.get(`/me`, { signal }).json<AgentResponse>()

export const getAllAgents: ApiRequest<void, AgentResponse[]> = (fetch, signal) =>
  fetch.get(`/agents`, { signal }).json<AgentResponse[]>()

export const toggleAgentRole: ApiRequest<AgentToggleRolePayload, void> = (
  fetch,
  payload
) => fetch.patch(`/agents/${payload.agentId}/roles/${payload.role}/toggle`).void()

export const toggleAgentState: ApiRequest<AgentToggleStatePayload, void> = (
  fetch,
  { id, ...json }
) => fetch.patch(`/agents/${id}/state`, { json }).void()
